import config from "components/UI/config";

import Immutable from "immutable";
const baseUrl = process.env.REACT_APP_BACKEND_URL;

const serieConfig={
  replay :{
    base : "/MesReplays",
    title: "Mes replays",
    path:"Tout",
    menus :{
      bpco: { label: "BPCO", path: "BPCO", description:"Explorez une collection de vidéos et de mini-séries animées par des experts de la BPCO", img:require('assets/images/bpco.png') },
      asthme: { label: "Asthme", path: "Asthme", description:"Plongez dans des vidéos et mini-séries présentées par des conférenciers renommés dans le domaine de l'Asthme", img:require('assets/images/asthme.png') },
      tech: { label: "Pneumo Innovations", path: "PneumoInnovations", description:"Découvrez des vidéos et mini-séries à travers l’expertise d’intervenants innovateurs en Pneumologie", img:require('assets/images/tech.png') },
      parcours: { label: "Parcours de Soins", path: "ParcoursSoins", description:"Accédez à des vidéos et mini-séries qui vous guideront à travers les différents parcours de soins", img:require('assets/images/parcours.png') },
      tout: { label: "Tous les replays", path: "Tout", description:"Parcourez notre sélection complète de vidéos et mini-séries"},
    },
  },
podcast: {
  base : "/MesPodcasts",
  title: "Mes podcasts",
  path:"Tout",  
  menus :{
    bpco: { label: "BPCO", path: "BPCO", description:"Écoutez des podcasts passionnants, animés par des experts de la BPCO", img:require('assets/images/podcast_bpco.png') },
    asthme: { label: "Asthme", path:"Asthme", description:" Immergez-vous dans le monde de l'asthme avec nos podcasts orchestrés par des spécialistes de l’Asthme", img:require('assets/images/podcast_asthme.png') },
    //tech: { label: "Pneumo Innovations", path:"PneumoInnovations", description:" Suivez les dernières innovations en pneumologie en écoutant nos podcasts avec des intervenants visionnaires et innovateurs", img:require('assets/images/podcast_tech.png') },
    tout: { label: "Tous les podcasts", path:"Tout", description:"Plongez dans notre collection complète de podcasts" },
  },
},
};


let local = {
  //newsHeight:285,//px
  newsHeight:20,//vw
  baseUrl :baseUrl,
  home: baseUrl + "/api/home?api=3",
  profile: baseUrl + "/api/profil/get?api=3",
  legal: baseUrl + "/api/page/get/legal",
  confidentiality: baseUrl + "/api/page/get/confidentiality",
  login: baseUrl + "/api/auth/login",
  private: baseUrl + "/api/private/login",
  logout: baseUrl + "/api/auth/logout",
  registration: baseUrl + "/api/registration",
  cms: baseUrl + "/api/cms",
  ping: baseUrl + "/api/ping",
  faq: baseUrl + "/api/faq",
  theme: baseUrl + "/api/theme",
  podcastTheme: baseUrl + "/api/podcastTheme",
  podcastShow: baseUrl + "/api/podcastShow",
  account: baseUrl + "/api/account",
  documents: baseUrl + "/api/document",
  message: baseUrl + "/api/message",
  orchestrator: baseUrl + "/api/orchestrator",
  quiz: baseUrl + "/api/orchestrator/quiz",
  utm: baseUrl + "/api/utm",
  feedback: baseUrl + "/api/feedback",
  news: baseUrl + "/api/news",
  newsthumbpreview: baseUrl + "/cache/news/{newsId}",
  speedtest: baseUrl + "/api/speedtest",
  stWorker: baseUrl + "/api/st",
  events: baseUrl + "/api/events",
  availableevents: baseUrl + "/api/availableevents",
  myevents: baseUrl + "/api/myevents",
  mypastevents: baseUrl + "/api/mypastevents",
  eventthumbpreview: baseUrl + "/cache/events/{eventId}",
  themethumbpreview: baseUrl + "/cache/themes/{themeId}",
  podcastthemethumbpreview: baseUrl + "/cache/podcastThemes/{themeId}",
  podcastshowthumbpreview: baseUrl + "/cache/podcastShow/{podcastId}",
  podcastshowlinkthumbpreview: baseUrl + "/cache/podcastShow/{podcastId}/links",
  eventSendInvite: baseUrl + "/api/events/invite",
  eventSubscribe: baseUrl + "/api/events/subscribe",
  eventUnsubscribe: baseUrl + "/api/events/unsubscribe",
  contact: baseUrl + "/api/contact",
  rpwd: baseUrl + "/api/auth/rpwd",
  cpwd: baseUrl + "/api/auth/cpwd",
  fb: "",
  ld: "",

  enableVideoTracker: true,
  cookieBannerConsent:true,//affiche le choix de consentement dans RGPDBanner


  serie:serieConfig,

  footerMenus:[
    {url:"/confidentiality", label:'Données personnelles'},
    {url:"/legal", label:'Conditions Générales'},
    {url:"/cookies", label:'Politique de cookies'},
    {url:"/help", label:'FAQ'},
    {url:"/contact", label:'Contact'},
  ],

  getJobs: () => {
    // if (process.env.REACT_APP_SITE === "pneumo" || process.env.REACT_APP_SITE === "pneumo_medevent")
    if (process.env.REACT_APP_SITE === "pneumo_medevent")
      return [
        { label: "Médecin", value: "md" },
        // {'label': 'Sage-femme', value: 'sf'},
        { label: "Chiesi", value: "lb" },
      ];
    else if (process.env.REACT_APP_SITE === "neonat")
      return [
        { label: "Médecin", value: "md" },
        { label: "Sage-femme", value: "sf" },
        { label: "Infirmier", value: "in" },
        { label: "Psychologue", value: "ps" },
        { label: "Médecin sans RPPS", value: "x2" },
        { label: "Interne", value: "x1" },
        { label: "Chiesi", value: "lb" },
      ];
    else if (process.env.REACT_APP_SITE === "transplant")
      return [
        { label: "Médecin", value: "md" },
        // { label: "Sage-femme", value: "sf" },
        { label: "Infirmier", value: "in" },
        { label: "Psychologue", value: "ps" },
        { label: "Médecin sans RPPS", value: "x2" },
        { label: "Interne", value: "x1" },
        // { label: "Chiesi", value: "lb" },
      ];
    else if (process.env.REACT_APP_SITE === "pneumo")
      return [
        { label: "Médecin", value: "md" },
        // { label: "Sage-femme", value: "sf" },
        // { label: "Psychologue", value: "ps" },
        { label: "Médecin sans RPPS", value: "x2" },
        { label: "Pharmacien", value: "ph" },
        { label: "Infirmier", value: "in" },
        { label: "Interne", value: "x1" },
        { label: "Chiesi", value: "lb" },
      ];
  },
  getJobsCategories: () => {
    // if (process.env.REACT_APP_SITE === "pneumo" || process.env.REACT_APP_SITE === "pneumo_medevent")
    if (process.env.REACT_APP_SITE === "pneumo_medevent")
      return {
        md: "isMd",
        lb: "isLb",
        ps: "isPs",
        x1: "isX1",
        x2: "isX2",
      };
    else if (process.env.REACT_APP_SITE === "neonat")
      return {
        md: "isMd",
        // sf: "isSf",
        lb: "isLb",
        sf: "isSf",
        in: "isIn",
        ps: "isPs",
        x1: "isX1",
        x2: "isX2",
      };
    else if (
      process.env.REACT_APP_SITE === "transplant" ||
      process.env.REACT_APP_SITE === "pneumo"
    )
      return {
        md: "isMd",
        // sf: "isSf",
        ps: "isPs",
        lb: "isLb",
        sf: "isSf",
        ph: "isPh",
        in: "isIn",
        x1: "isX1",
        x2: "isX2",
      };
  },
  getMenu: ($$user, options, $$availableEvents) => {
    //console.log($$user ? $$user.toJS() : $$user)
    //console.log("availableEvents", $$availableEvents ? $$availableEvents.toJS() : $$availableEvents);
    const nbEvents=$$availableEvents? $$availableEvents.size : 0;
    let userRole;
    let userSubRole;
    if ($$user === undefined)
      userRole = "visitor";
    else {
      userRole = $$user.get("role");
      userSubRole = $$user.get("subRole");
    }
    let menu = [];
    if (userRole === undefined || userRole === "visitor" || nbEvents===0) {
      menu.push({ icon: "home", title: "Accueil", url: "/" });
    } else {
      menu.push({ icon: "home", title: "Mes webinaires", url: "/" });
    }

    // if (userRole === 'member' || userRole === 'speaker' || userRole === 'admin') {
    // menu.push({ icon: 'lives', title: 'Live', url: '/live'})
    // menu.push({ icon: 'replays', title: 'Replay', url: '/replays'})
    // }
    // if (userRole !== 'speaker') {
    // menu.push({ icon: 'agenda', title: 'Agenda', url: '/agenda'})
    // }

    /*if (userRole === "staff") {
      menu.push({
        icon: "event",
        title: "Emissions",
        url: "/staff/event/list",
      });
    }*/

    // if ((process.env.REACT_APP_SITE === "pneumo") && userRole !== undefined && userRole !== 'visitor')
    // menu.push({ icon: "documents", title: "Fiches Patients", url: "/documents" });
    if (process.env.REACT_APP_SITE === "pneumo") {

      Object.keys(serieConfig).forEach(type=>{
        let subMenu={};
        Object.keys(serieConfig[type].menus).forEach(key=>{
          subMenu[serieConfig[type].menus[key].label] = serieConfig[type].base+"/"+serieConfig[type].menus[key].path;
        });
        menu.push({
          icon: type,
          title: serieConfig[type].title,
          url: serieConfig[type].base+"/"+serieConfig[type].path,
          subMenu: subMenu,
        });
      });
/*
      menu.push({
        icon: "documents",
        title: "Fiches Patients",
        url: "/documents",
      });
      menu.push({ icon: "podcasts", title: "Podcasts", url: "/podcasts" });
  */
    }
    // if ((process.env.REACT_APP_SITE === "transplant" || process.env.REACT_APP_SITE === "pneumo") && userRole !== undefined && userRole !== 'visitor')
    if (
      process.env.REACT_APP_SITE === "transplant" &&
      userRole !== undefined &&
      userRole !== "visitor"
    )
      menu.push({ icon: "replays", title: "Replays", url: "/replays" });
    /*else if (process.env.REACT_APP_SITE === "pneumo")
      menu.push({ icon: "replays", title: "Replays", url: "/replays" });*/

      /*
    menu.push({ icon: "help", title: "Aide", url: "/help" });
    menu.push({ icon: "contact", title: "Contact", url: "/contact" });
    */

    return options !== undefined && options.asImmutable === true
      ? Immutable.fromJS(menu)
      : menu;
  },
  getProfileMenu: ($$user, options) => {
    let userRole;
    let userSubRole;
    if ($$user === undefined) userRole = "visitor";
    else {
      userRole = $$user.get("role");
      userSubRole = $$user.get("subRole");
    }
    let menu = [];
    // if (userRole === 'member')
    // menu.push({ title: 'Mes événements', url: '/my_events'})
    if (userRole === "member" || userRole === "speaker" || userRole === "staff")
      menu.push({ title: "Mon compte", url: "/account" });
    //if (userRole === "member")
    //  menu.push({ title: "Mes fichiers", url: "/files" });
    if (userRole === "admin") {
      // menu.push({ icon: 'admin', title: 'Admin', url: '/admin'})
      menu.push({
        icon: "account",
        title: "Professionnels",
        url: "/staff/account/list",
      });
      menu.push({ icon: "theme", title: "Thèmes", url: "/staff/theme/list" });
      menu.push({
        icon: "event",
        title: "Émissions",
        url: "/staff/event/list",
      });
      /*menu.push({
        icon: "podcastTheme",
        title: "Podcasts Thèmes",
        url: "/staff/podcast/theme/list",
      });*/
      menu.push({
        icon: "podcatShow",
        title: "Podcasts",
        url: "/staff/podcast/show/list",
      });
      menu.push({
        icon: "serie",
        title: "Séries",
        url: "/staff/serie/list",
      });
      menu.push({
        icon: "episode",
        title: "Épisodes",
        url: "/staff/episode/list",
      });
      menu.push({ icon: "news", title: "News", url: "/staff/news/list" });
      menu.push({ icon: "cms", title: "CMS", url: "/staff/cms/list" });
      menu.push({
        icon: "utm",
        title: "UTMs",
        url: "/staff/utm/list",
      });
      menu.push({ icon: "faq", title: "FAQ", url: "/staff/faq/list" });
      menu.push({
        icon: "message",
        title: "Moderation",
        url: "/staff/orchestrator/list",
      });
      menu.push({
        icon: "email",
        title: "Emails",
        url: "/api/_cron/mail",
        target: "_blank",
      });
      menu.push({
        icon: "logs",
        title: "Logs",
        url: "/api/_logs",
        target: "_blank",
      });
      menu.push({
        icon: "export",
        title: "Exports",
        url: "/api/_exports",
        target: "_blank",
      });
    }
    if (userSubRole === "buadm") {
      menu.push({
        icon: "account",
        title: "Professionnels",
        url: "/staff/account/list",
      });
    }
    if (
      userSubRole === "mod" ||
      userSubRole === "buadm" ||
      userRole === "speaker"
    ) {
      menu.push({
        icon: "moderation",
        title: "Moderation",
        url: "/staff/orchestrator/list",
      });
    }
    if (userSubRole === "buadm") {
      menu.push({
        icon: "export",
        title: "Exports",
        url: "/api/_exports",
        target: "_blank",
      });
    }

    menu.push({ title: "Se déconnecter", url: "/logout" });
    return options !== undefined && options.asImmutable === true
      ? Immutable.fromJS(menu)
      : menu;
  },
};

export default { ...config, ...local };
